/* fira-sans-300 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/src/assets/fonts/fira-sans-v16-latin-300.eot"); /* IE9 Compat Modes */
  src: url("/src/assets/fonts/fira-sans-v16-latin-300.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/src/assets/fonts/fira-sans-v16-latin-300.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/src/assets/fonts/fira-sans-v16-latin-300.woff") format("woff"),
    /* Modern Browsers */ url("/src/assets/fonts/fira-sans-v16-latin-300.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/src/assets/fonts/fira-sans-v16-latin-300.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}

/* fira-sans-regular - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/src/assets/fonts/fira-sans-v16-latin-regular.eot"); /* IE9 Compat Modes */
  src: url("/src/assets/fonts/fira-sans-v16-latin-regular.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/src/assets/fonts/fira-sans-v16-latin-regular.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/src/assets/fonts/fira-sans-v16-latin-regular.woff") format("woff"),
    /* Modern Browsers */
      url("/src/assets/fonts/fira-sans-v16-latin-regular.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/src/assets/fonts/fira-sans-v16-latin-regular.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}

/* fira-sans-500 - latin */
@font-face {
  font-display: swap; /* Check https://developer.mozilla.org/en-US/docs/Web/CSS/@font-face/font-display for other options. */
  font-family: "Fira Sans";
  font-style: normal;
  font-weight: 500;
  src: url("/src/assets/fonts/fira-sans-v16-latin-500.eot"); /* IE9 Compat Modes */
  src: url("/src/assets/fonts/fira-sans-v16-latin-500.eot?#iefix")
      format("embedded-opentype"),
    /* IE6-IE8 */ url("/src/assets/fonts/fira-sans-v16-latin-500.woff2")
      format("woff2"),
    /* Super Modern Browsers */
      url("/src/assets/fonts/fira-sans-v16-latin-500.woff") format("woff"),
    /* Modern Browsers */ url("/src/assets/fonts/fira-sans-v16-latin-500.ttf")
      format("truetype"),
    /* Safari, Android, iOS */
      url("/src/assets/fonts/fira-sans-v16-latin-500.svg#FiraSans")
      format("svg"); /* Legacy iOS */
}
