.button {
  border: none;
  cursor: pointer;
  display: grid;
  padding: 8px 16px;
  color: var(--text-primary);
  align-items: center;
  justify-content: center;

  &_primary {
    transition: background-position 0.4s ease-in-out;
    background: linear-gradient(
      to right,
      var(--color-primary),
      var(--color-secondary),
      var(--color-tertiary)
    );
    background-size: 200%;
    &:hover,
    &:focus {
      background-position: 100% 0;
    }
  }

  &_secondary {
    transition: box-shadow 0.4s ease-in-out;
    background-color: var(--background-secondary);
    box-shadow: 0 2px 40px var(--color-secondary);
    &:hover,
    &:focus {
      box-shadow: 0 2px 40px var(--color-primary);
    }
  }

  &_md {
    padding: 10px 18px;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }

  &_lg {
    padding: 16px 36px;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    letter-spacing: 0.005em;
  }

  &_rounded {
    &_sm {
      border-radius: 6px;
    }
    &_md {
      border-radius: 42px;
    }
  }

  &:focus {
    outline: 2px solid var(--color-secondary);
    outline-offset: 4px;
  }

  &_arrow {
    grid-template-columns: auto 1fr auto;
    &:before {
      content: "";
    }
    &:after {
      content: "";
      background-image: url("/src/assets/icons/arrow.svg");
      width: 25px;
      height: 24px;
    }
  }

  &_chevron {
    grid-template-columns: auto 1fr auto;
    &:before {
      content: "";
    }
    &:after {
      content: "";
      background-image: url("/src/assets/icons/chevron.svg");
      width: 16px;
      height: 16px;
      background-size: contain;
    }

    &_back {
      grid-template-columns: auto 1fr auto;
      &:before {
        content: "";
        background-image: url("/src/assets/icons/chevron.svg");
        transform: rotate(180deg);
        width: 16px;
        height: 16px;
        background-size: contain;
      }
      &:after {
        content: "";
      }
    }
  }

  &_full {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding: 8px 16px;
    border-radius: 39px;
    font-size: 14px;

    &_md {
      padding: 12px 24px;
      border-radius: 50px;
      font-size: 14px;
      line-height: 17px;
    }
  }
}
