.container {
  width: 100%;
  max-width: var(--screen-width);

  &_centered {
    margin-left: auto;
    margin-right: auto;
  }

  &_content {
    width: 100%;
    max-width: calc(var(--screen-width) - (var(--default-padding) * 2));
  }
}
