@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 300;
  src: url("fira-sans-v16-latin-300.dacc2c8e.eot");
  src: url("fira-sans-v16-latin-300.dacc2c8e.eot#iefix") format("embedded-opentype"), url("fira-sans-v16-latin-300.cca2fc67.woff2") format("woff2"), url("fira-sans-v16-latin-300.8656da7d.woff") format("woff"), url("fira-sans-v16-latin-300.a54b179b.ttf") format("truetype"), url("fira-sans-v16-latin-300.b2289bca.svg#FiraSans") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 400;
  src: url("fira-sans-v16-latin-regular.fc48f607.eot");
  src: url("fira-sans-v16-latin-regular.fc48f607.eot#iefix") format("embedded-opentype"), url("fira-sans-v16-latin-regular.4e44348d.woff2") format("woff2"), url("fira-sans-v16-latin-regular.7f5153aa.woff") format("woff"), url("fira-sans-v16-latin-regular.59520449.ttf") format("truetype"), url("fira-sans-v16-latin-regular.b3e8978d.svg#FiraSans") format("svg");
}

@font-face {
  font-display: swap;
  font-family: Fira Sans;
  font-style: normal;
  font-weight: 500;
  src: url("fira-sans-v16-latin-500.ef9f4ae0.eot");
  src: url("fira-sans-v16-latin-500.ef9f4ae0.eot#iefix") format("embedded-opentype"), url("fira-sans-v16-latin-500.298c3c81.woff2") format("woff2"), url("fira-sans-v16-latin-500.7f3de9d5.woff") format("woff"), url("fira-sans-v16-latin-500.f40b7ea5.ttf") format("truetype"), url("fira-sans-v16-latin-500.e83330a5.svg#FiraSans") format("svg");
}

*, :before, :after {
  box-sizing: border-box;
}

html {
  background-color: var(--background-primary);
  color: var(--text-primary);
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--header-height));
  font-family: Fira Sans, serif;
  overflow-x: hidden;
}

body {
  text-rendering: optimizespeed;
  min-height: 100vh;
  position: relative;
  overflow-x: hidden;
}

a {
  color: var(--text-primary);
  text-decoration: none;
}

a:hover:not(.button):not(.logotype), a:focus:not(.button):not(.logotype) {
  background-image: linear-gradient(45deg, var(--color-primary), var(--color-tertiary));
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

button {
  transition: all .4s ease-in-out;
}

button:focus {
  outline: 1px solid var(--color-secondary);
  outline-offset: 3px;
}

html, body, h1, h2, h3, h4, a, p, span, ul, dl, dt, dd {
  margin: 0;
  padding: 0;
}

fieldset {
  all: unset;
}

.title {
  color: var(--text-primary);
  margin-top: 12px;
  font-size: 32px;
  font-weight: 400;
  line-height: 35px;
}

.subtitle {
  letter-spacing: .16em;
  text-transform: uppercase;
  background-image: linear-gradient(45deg, var(--color-primary), var(--color-tertiary));
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 12px;
  font-weight: 500;
  line-height: 14px;
}

.description {
  color: var(--text-secondary);
  margin-top: 24px;
  font-size: 16px;
  font-weight: 300;
  line-height: 19px;
}

.sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.code {
  counter-reset: line-numbering;
  color: var(--text-tertiary);
  padding: 24px 20px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
}

.code__line:before {
  content: counter(line-numbering);
  counter-increment: line-numbering;
  width: 20px;
  text-align: left;
  opacity: .4;
  color: var(--text-primary);
  margin-right: 12px;
  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  display: inline-block;
}

img {
  -webkit-user-select: none;
  user-select: none;
  pointer-events: none;
}

.button {
  cursor: pointer;
  color: var(--text-primary);
  border: none;
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  display: grid;
}

.button_primary {
  background: linear-gradient(to right, var(--color-primary), var(--color-secondary), var(--color-tertiary));
  background-size: 200%;
  transition: background-position .4s ease-in-out;
}

.button_primary:hover, .button_primary:focus {
  background-position: 100% 0;
}

.button_secondary {
  background-color: var(--background-secondary);
  box-shadow: 0 2px 40px var(--color-secondary);
  transition: box-shadow .4s ease-in-out;
}

.button_secondary:hover, .button_secondary:focus {
  box-shadow: 0 2px 40px var(--color-primary);
}

.button_md {
  padding: 10px 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.button_lg {
  letter-spacing: .005em;
  padding: 16px 36px;
  font-size: 18px;
  font-weight: 500;
  line-height: 22px;
}

.button_rounded_sm {
  border-radius: 6px;
}

.button_rounded_md {
  border-radius: 42px;
}

.button:focus {
  outline: 2px solid var(--color-secondary);
  outline-offset: 4px;
}

.button_arrow {
  grid-template-columns: auto 1fr auto;
}

.button_arrow:before {
  content: "";
}

.button_arrow:after {
  content: "";
  width: 25px;
  height: 24px;
  background-image: url("arrow.d7a33b77.svg");
}

.button_chevron {
  grid-template-columns: auto 1fr auto;
}

.button_chevron:before {
  content: "";
}

.button_chevron:after {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("chevron.dd84db23.svg");
  background-size: contain;
}

.button_chevron_back {
  grid-template-columns: auto 1fr auto;
}

.button_chevron_back:before {
  content: "";
  width: 16px;
  height: 16px;
  background-image: url("chevron.dd84db23.svg");
  background-size: contain;
  transform: rotate(180deg);
}

.button_chevron_back:after {
  content: "";
}

.button_full {
  width: 100%;
}

@media (max-width: 768px) {
  .button {
    border-radius: 39px;
    padding: 8px 16px;
    font-size: 14px;
  }

  .button_md {
    border-radius: 50px;
    padding: 12px 24px;
    font-size: 14px;
    line-height: 17px;
  }
}

.container {
  width: 100%;
  max-width: var(--screen-width);
}

.container_centered {
  margin-left: auto;
  margin-right: auto;
}

.container_content {
  width: 100%;
  max-width: calc(var(--screen-width)  - var(--default-padding) * 2);
}

@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar:vertical {
  width: 12px;
}

::-webkit-scrollbar:horizontal {
  height: 12px;
}

::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}

::-webkit-scrollbar-thumb:horizontal {
  background-image: linear-gradient(90deg, var(--color-primary), var(--color-secondary));
}

::-webkit-scrollbar-thumb:vertical {
  background-image: linear-gradient(180deg, var(--color-primary), var(--color-secondary));
}

:root {
  --background-primary: #101318;
  --background-secondary: #0d0d0d;
  --background-tertiary: #1b1b1b;
  --color-primary: #9104ff;
  --color-secondary: #2a3ac8;
  --color-tertiary: #00b2ff;
  --color-error: #dc2626;
  --text-primary: #fff;
  --text-secondary: #d1d5db;
  --text-tertiary: #a7f3d0;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --blue-500: #3b82f6;
  --green-500: #10b981;
  --header-height: 126px;
  --default-padding: 16px;
  --screen-width: 100%;
}

@media (max-width: 768px) {
  :root {
    --header-height: 74px;
  }
}

@media (min-width: 768px) {
  :root {
    --default-padding: 40px;
  }
}

@media (min-width: 640px) {
  :root {
    --screen-width: 640px;
  }
}

@media (min-width: 768px) {
  :root {
    --screen-width: 768px;
  }
}

@media (min-width: 1024px) {
  :root {
    --screen-width: 1024px;
  }
}

@media (min-width: 1280px) {
  :root {
    --screen-width: 1280px;
  }
}

.loader {
  width: 100vw;
  height: 100vh;
  z-index: 99999;
  background-color: #00000080;
  justify-content: center;
  align-items: center;
  display: none;
  position: fixed;
  inset: 0;
}

.loader__item {
  width: 48px;
  height: 48px;
  box-sizing: border-box;
  border: 5px solid #fff;
  border-bottom-color: #0000;
  border-radius: 50%;
  animation: 1s linear infinite rotation;
  display: inline-block;
}

:root {
  --animate-duration: .3s;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

[data-animation] {
  opacity: 0;
}

[data-animation].animate {
  opacity: 1;
}

@media (min-width: 768px) {
  .animation-group [data-animation] {
    --animation-group-delay: .15s;
  }

  .animation-group [data-animation]:nth-child(1) {
    animation-delay: calc(0 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(2) {
    animation-delay: calc(1 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(3) {
    animation-delay: calc(2 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(4) {
    animation-delay: calc(3 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(5) {
    animation-delay: calc(4 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(6) {
    animation-delay: calc(5 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(7) {
    animation-delay: calc(6 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(8) {
    animation-delay: calc(7 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(9) {
    animation-delay: calc(8 * var(--animation-group-delay));
  }

  .animation-group [data-animation]:nth-child(10) {
    animation-delay: calc(9 * var(--animation-group-delay));
  }
}

/*# sourceMappingURL=application.87c18cc3.css.map */
