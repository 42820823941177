:root {
  --background-primary: #101318;
  --background-secondary: #0d0d0d;
  --background-tertiary: #1b1b1b;

  --color-primary: #9104ff;
  --color-secondary: #2a3ac8;
  --color-tertiary: #00b2ff;
  --color-error: #dc2626;

  --text-primary: #ffffff;
  --text-secondary: #d1d5db;
  --text-tertiary: #a7f3d0;
  --gray-500: #6b7280;
  --gray-700: #374151;
  --blue-500: #3b82f6;
  --green-500: #10b981;

  --header-height: 126px;
  @media (max-width: 768px) {
    --header-height: 74px;
  }

  --default-padding: 16px;
  @media (min-width: 768px) {
    --default-padding: 40px;
  }

  --screen-width: 100%;
  @media (min-width: 640px) {
    --screen-width: 640px;
  }
  @media (min-width: 768px) {
    --screen-width: 768px;
  }
  @media (min-width: 1024px) {
    --screen-width: 1024px;
  }
  @media (min-width: 1280px) {
    --screen-width: 1280px;
  }
}
