*,
*::before,
*::after {
  box-sizing: border-box;
}

html {
  font-family: "Fira Sans", serif;
  background-color: var(--background-primary);
  color: var(--text-primary);
  overflow-x: hidden;
  scroll-behavior: smooth;
  scroll-padding-top: calc(var(--header-height));
}

body {
  text-rendering: optimizeSpeed;
  overflow-x: hidden;
  position: relative;
  min-height: 100vh;
}

a {
  text-decoration: none;
  color: var(--text-primary);

  &:hover:not(.button):not(.logotype),
  &:focus:not(.button):not(.logotype) {
    background-image: linear-gradient(
      45deg,
      var(--color-primary),
      var(--color-tertiary)
    );
    background-size: 100%;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }
}

button {
  transition: all 0.4s ease-in-out;

  &:focus {
    outline: 1px solid var(--color-secondary);
    outline-offset: 3px;
  }
}

html,
body,
h1,
h2,
h3,
h4,
a,
p,
span,
ul,
dl,
dt,
dd {
  padding: 0;
  margin: 0;
}

fieldset {
  all: unset;
}

.title {
  margin-top: 12px;
  font-weight: 400;
  font-size: 32px;
  line-height: 35px;
  color: var(--text-primary);
}

.subtitle {
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  letter-spacing: 0.16em;
  text-transform: uppercase;
  background-image: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-tertiary)
  );
  background-size: 100%;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.description {
  margin-top: 24px;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: var(--text-secondary);
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

.code {
  counter-reset: line-numbering;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: var(--text-tertiary);
  padding: 24px 20px;

  &__line::before {
    display: inline-block;
    content: counter(line-numbering);
    counter-increment: line-numbering;

    width: 20px;
    text-align: left;
    opacity: 0.4;
    color: var(--text-primary);
    font-weight: 700;
    font-size: 14px;
    line-height: 24px;
    margin-right: 12px;
  }
}

img {
  user-select: none;
  pointer-events: none;
}
