@keyframes rotate {
  to {
    transform: rotate(1turn);
  }
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    transform: translate3d(-20%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translateZ(0);
  }
}

.fadeInLeft {
  animation-name: fadeInLeft;
}
