::-webkit-scrollbar {
  width: 12px;
}
::-webkit-scrollbar:vertical {
  width: 12px;
}
::-webkit-scrollbar:horizontal {
  height: 12px;
}
::-webkit-scrollbar-track {
  background-color: var(--background-secondary);
}
::-webkit-scrollbar-thumb:horizontal {
  background-image: linear-gradient(
    90deg,
    var(--color-primary),
    var(--color-secondary)
  );
}
::-webkit-scrollbar-thumb:vertical {
  background-image: linear-gradient(
    180deg,
    var(--color-primary),
    var(--color-secondary)
  );
}
