:root {
  --animate-duration: 300ms;
  --animate-delay: 1s;
  --animate-repeat: 1;
}

.animated {
  animation-duration: var(--animate-duration);
  animation-fill-mode: both;
}

.fadeInUp {
  animation-name: fadeInUp;
}

.fadeInLeft {
  animation-name: fadeInLeft;
}

[data-animation] {
  opacity: 0;

  &.animate {
    opacity: 1;
  }
}

.animation-group [data-animation] {
  @media (min-width: 768px) {
    --animation-group-delay: 150ms;

    @for $i from 1 to 11 {
      &:nth-child(#{$i}) {
        animation-delay: calc(#{$i - 1} * var(--animation-group-delay));
      }
    }
  }
}
